import React from 'react';
import Grid from '@material-ui/core/Grid';

import recruitExpressLogo from "../../assets/img/recruit-express.png";
import amazonLogo from "../../assets/img/Amazon-logo.png";
import hmLogo from "../../assets/img/H&M-Logo.png";
// import smokeMirrosLogo from "../../assets/img/SMOKEMIRROR Logo.png";
import BWMLOGO from "../../assets/img/BMW-Logo.png";
import GBBLOGO from "../../assets/img/GBBLogo.png";
import TODSLOGO from "../../assets/img/TodsLogo.png";
import DIESELLOGO from "../../assets/img/DieselLogo.png";
import FUJIFILMLOGO from "../../assets/img/FUJIFILM-Logo.png";
// import asiaFarmLogo from "../../assets/img/asiaFarmLogo.png";
import centralPatanaLogo from "../../assets/img/CentralPatana.png";
import { withTranslation } from 'react-i18next';
import "./index.css";

class OurClients extends React.Component {

    componentDidMount() {

    }

    render() {
        const { t } = this.props;
        return (
            <Grid id="ourclient" container className={"OurClientsContainer"}>
                <Grid container justify='center' alignItems='center'>
                    <div className={'OurClients-Header'}>
                        {t('HOME_PAGE.Some of Our Clients')}
                    </div>
                </Grid>
                <Grid className='pt-24' container justify='center' alignItems='center' spacing={10}>
                    <Grid item xs={12} sm={1} className={"OurClientItem"}>
                        <img src={amazonLogo} alt="Amazon" className={"OurClientsIcon amazonLogo"} />
                    </Grid>
                    <Grid item xs={12} sm={1} className={"OurClientItem"}>
                        <img src={BWMLOGO} alt="BMW" className={"OurClientsIcon BMWLogo"} />
                    </Grid>
                  
                    {/* <Grid item xs={12} sm={1} className={"OurClientItem"}>
                        <img src={asiaFarmLogo} alt="Asia Farm" className={"OurClientsIcon asiaFarmLogo"} />
                    </Grid> */}
                    <Grid item xs={12} sm={2} className={"OurClientItem"}>
                        <img src={centralPatanaLogo} alt="Central Patana Logo" className={"OurClientsIcon CentralPatanaLogo"} />
                    </Grid>
                    <Grid item xs={12} sm={1} className={"OurClientItem"}>
                        <img src={DIESELLOGO} alt="Diesel" className={"OurClientsIcon dieselLogo"} />
                    </Grid>
                    <Grid item xs={12} sm={1} className={"OurClientItem"}>
                        <img src={GBBLOGO} alt="Garden by the Bay" className={"OurClientsIcon GBBLogo"} />
                    </Grid>
                    <Grid item xs={12} sm={1} className={"OurClientItem"}>
                        <img src={FUJIFILMLOGO} alt="FUJIFILM" className={"OurClientsIcon fujiLogo"} />
                    </Grid>
                    <Grid item xs={12} sm={1} className={"OurClientItem"}>
                        <img src={hmLogo} alt="H&M" className={"hmLogoIcon"} />
                    </Grid>
                    <Grid item xs={12} sm={1} className={"OurClientItem"}>
                        <img src={recruitExpressLogo} alt="Recruit Express" className={"OurClientsIcon RELogo"} />
                    </Grid>
                    <Grid item xs={12} sm={1} className={"OurClientItem"}>
                        <img src={TODSLOGO} alt="Tod's" className={"OurClientsIcon TodLogo"} />
                    </Grid>
                  
                    {/* <Grid item xs={12} sm={1} className={"OurClientItem"}>
                        <img src={yanLogo} alt="Yan Logo" className={"OurClientsIcon yanLogo"} />
                    </Grid> */}

                </Grid>
            </Grid>
        )
    }
}

export default withTranslation()(OurClients)